import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({ fill }) {
  return (
<svg viewBox="0 0 200 100" height="60px">
                <path fill={fill} d="M45.6440596,3.23735986 C46.5510787,5.69182398 45.410236,8.43150219 43.0315835,9.51109423 C29.4085612,15.7881891 21.3810349,23.1227258 21.3810349,34.927882 C21.3810349,45.9683034 30.8830496,53.1121054 42.1125352,55.0604151 C44.0561134,55.0604151 46.647551,57.8746208 46.647551,62.2042751 C45.8980293,69.7768456 39.3257138,75.4103411 31.7467851,74.9765465 L31.5308512,74.9765465 C13.798729,74.3368866 -0.19059185,59.6410304 0.00196411559,41.8552825 C0.00196411559,20.0955369 19.6436227,6.21130301 39.6016105,0.203392074 C42.0975772,-0.533731332 44.7369757,0.790889023 45.6437706,3.23573758 L45.6440596,3.23735986 Z M99.9965086,3.23735986 C100.903528,5.69182398 99.7626851,8.43150219 97.3840326,9.51109423 C83.7610102,15.7881891 75.7334839,23.1227258 75.7334839,34.927882 C75.7334839,45.9683034 85.2354987,53.1121054 96.4649843,55.0604151 C98.4085625,55.0604151 101,57.8746208 101,62.2042751 C100.450254,69.7004545 94.0186154,75.3779405 86.5311596,74.9765465 L86.0992341,74.9765465 C68.3787396,74.385556 54.423969,59.6250392 54.7859919,41.8552825 C54.7859919,20.0987235 74.0478589,6.21564842 93.9508807,0.206057261 C96.4476584,-0.533135979 99.0891647,0.791338698 99.9965664,3.2374178 L99.9965086,3.23735986 Z" id="Shape" fill-rule="nonzero"></path>
                <text id="Carrie-Lau" font-family="BigCaslon-Medium, Big Caslon" font-size="32" font-weight="400">
                    <tspan x="117" y="242">Carrie Lau</tspan>
                </text>
</svg>
    // <svg viewBox="0 0 106 28" height="30px">
    //   <path d="M45.6440596,3.23735986 C46.5510787,5.69182398 45.410236,8.43150219 43.0315835,9.51109423 C29.4085612,15.7881891 21.3810349,23.1227258 21.3810349,34.927882 C21.3810349,45.9683034 30.8830496,53.1121054 42.1125352,55.0604151 C44.0561134,55.0604151 46.647551,57.8746208 46.647551,62.2042751 C45.8980293,69.7768456 39.3257138,75.4103411 31.7467851,74.9765465 L31.5308512,74.9765465 C13.798729,74.3368866 -0.19059185,59.6410304 0.00196411559,41.8552825 C0.00196411559,20.0955369 19.6436227,6.21130301 39.6016105,0.203392074 C42.0975772,-0.533731332 44.7369757,0.790889023 45.6437706,3.23573758 L45.6440596,3.23735986 Z M99.9965086,3.23735986 C100.903528,5.69182398 99.7626851,8.43150219 97.3840326,9.51109423 C83.7610102,15.7881891 75.7334839,23.1227258 75.7334839,34.927882 C75.7334839,45.9683034 85.2354987,53.1121054 96.4649843,55.0604151 C98.4085625,55.0604151 101,57.8746208 101,62.2042751 C100.450254,69.7004545 94.0186154,75.3779405 86.5311596,74.9765465 L86.0992341,74.9765465 C68.3787396,74.385556 54.423969,59.6250392 54.7859919,41.8552825 C54.7859919,20.0987235 74.0478589,6.21564842 93.9508807,0.206057261 C96.4476584,-0.533135979 99.0891647,0.791338698 99.9965664,3.2374178 L99.9965086,3.23735986 Z" id="Shape" fill-rule="nonzero"></path>
    //   <path fill={fill} d="M25 14h-..." />
    //   <path d="M14 0C6.3..." fill="#639" />
    // </svg>
  );
}